<template>
  <div class="box">
    <div>
      <van-nav-bar title="分享" left-arrow @click-left="goBack">
        <template #right>
          <img
            @click="shareCoupon"
            style="width: 20px; height: 20px"
            src="../../../public/img/分享.png"
            alt=""
          />
        </template>
      </van-nav-bar>
    </div>
    <div class="content">
      <div class="img">
        <img style="width: 100%; height: 100%" :src="this.couponList.imgUrl" />
      </div>
      <div class="title">{{ this.couponList.name }}</div>
      <div
        class="list"
        v-for="(item, index) in couponList.couponInfoList"
        :key="index"
      >
        <div class="list-discount">
          <div>
            <span v-if="item.discount"
              >￥<span style="font-size: 16px; margin: 0 4px"
                >{{ item.discount }}折劵</span
              ></span
            >
            <span v-else
              >￥<span style="font-size: 16px; margin: 0 4px">{{
                item.reduceAmount
              }}</span></span
            >
            <span style="font-size: 14px"
              >订单满{{ item.premiseAmount }}可用</span
            >
          </div>
          <div class="list-discount-text">
            {{ item.suitType == 1 ? "全部商品可用" : "指定商品可用" }}
          </div>
        </div>
        <div class="date" :style="{visibility: !item.discount? 'hidden' : ''}">
          截止使用日期：{{ item.validBeginTime ? item.validBeginTime.substr(0,10): '' }}&nbsp;至&nbsp;{{ item.validEndTime ? item.validEndTime.substr(0,10): ''}}
        </div>
        <div class="list-pic">
          <img v-if="item.checkData" src="../../assets/组 9470@2x.png" />
        </div>
      </div>
      <div style="height: 12px"></div>
    </div>
    <div class="button" style="margin: 15px 15px 8px 16px">
      <van-goods-action-button
        @click="clickCoupon"
        type="primary"
        :text="textWord"
      />
    </div>
    <div>
      <div class="love">
        <span class="straight"></span>
        <span class="suspect">猜你喜欢</span>
        <span class="straight"></span>
      </div>
    </div>
    <div class="lovelist">
      <div class="lovelist-item" v-for="x in youLike" :key="x.id">
        <img :src="x.picUrls[0]" alt="">
        <span>{{ x.name }}</span>
        <p>{{ x.priceDown | price }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getShopCoupon, getCouponList } from "@/api/shareVoucher";
export default {
  data() {
    return {
      couponList: [],
      youLike: [],
      textWord: "1111",
      isGetCoupon: null,
      arrConpon: [] //没有领取的劵
    }
  },

  filters: {
    price: (price, sign = '￥') => {
      const str = String(price)
      let jiage = null
      if (str.indexOf('.') == -1) {
        jiage = str + '.00'
      } else if (str.split('.')[1].length == 1) {
        jiage = str + '0'
      } else if (str.split('.')[1].length == 2) {
        jiage = str
      }
      return sign + jiage
    }
  },

  created() {
    this.$store.commit("setSession", this.$route.query.session || '$2a$10$kMsFSlZWuBAjtCnYaLHHj.MSfp722zqzcVEdRDlO/iK1tIe0q8Qxy');
    this.getShopCoupon();
  },

  mounted () {
    let screenWidth = document.documentElement.getBoundingClientRect().width;
    document.documentElement.style.fontSize = screenWidth / 10 + "px";
  },
  methods: {
    // 回到上一页
    goBack() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    // 点击领劵
    clickCoupon() {
      if (this.textWord == "领劵") {
        let ids = [];
        this.arrConpon.map((item) => {
          ids.push(item.id);
        });
        console.log(ids, "ids");
        getCouponList(ids).then((res) => {
          if (res.data.code === 0) {
            this.$Toast("领取成功");
            this.getShopCoupon();
          } else {
            Toast(res.data.message);
          }
        });
      } else {
        console.log("去使用");
        // 去使用方法goUse
        this.$router.push({
          path: "/shopDetail",
          query: {
            id: this.couponList.id,
          },
        });
        // let u = navigator.userAgent;
        // let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        // if (isIOS) {
        //   window.webkit.messageHandlers.goUse.postMessage("up");
        // }
        // if (isAndroid) {
        //   window.android.goUse();
        // }
      }
    },
    // 最上面的分享
    shareCoupon() {
      let params = {
        url: "http://192.168.0.100:8082/#/shareVoucher?session=" + this.$route.query.session + '&shopId=' + this.$route.query.shopId + '&type=1',
        shopImgUrl: this.couponList.imgUrl,
        shopName: this.couponList.name,
      };
      console.log(params, "params");
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.shareCoupon.postMessage(params);
      }
      if (isAndroid) {
        window.android.shareCoupon(params);
      }
    },
    // 分享首页  店铺初始化显示的数据
    getShopCoupon() {
      let params = {
        current: 1,
        size: 10,
        shopId: this.$route.query.shopId || "db1f01a7a20842448201ece8cc7717c6",
      };
      getShopCoupon(params).then((res) => {
        if (res.data.code === 0) {
          this.couponList = res.data.data.shopInfo[0];
          this.youLike = res.data.data.goodsSpuList;
          this.couponList.couponInfoList.forEach(item => {
             if(!item.checkData) {
               this.arrConpon.push(item)
             }
          })
          if(this.arrConpon.length){
            this.textWord = "领劵"
          } else {
            this.textWord = "去使用"
          }
          if(this.$route.query.type){
            this.openApp(
              "/shareVoucher?session=" + this.$route.query.session + '&shopId=' + this.$route.query.shopId,
              false
            );
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.lovelist {
  margin: 0 13px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-item {
    width: 4.3784rem;
    height: 6.4054rem;
    background: white;
    position: relative;
    margin-bottom: 0.2703rem;
    img {
      width: 100%;
      max-height: 3.7297rem;
    }
    span {
      font-size: 0.4054rem;
      padding: 0 0.1622rem;
      box-sizing: border-box;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
      position: absolute;
      left: 0.1622rem;
      top: 4rem;
    }
    p {
      font-size: 0.4054rem;
      color: #F42C20;
      position: absolute;
      left: 0.1622rem;
      bottom: 0.3243rem;
    }
  }
}
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
/deep/.van-button {
  background-color: #f52556;
}
.box {
  background-color: #efefef;
  height: 100%;
  //   overflow: hidden;
  padding-bottom: 10px;
  padding-top: 90px;
}
.content {
  background-color: #fff;
  position: relative;
  // height: 300px;
  margin: 0px 10px 0 10px;
  // margin-top: 90px;
  .img {
    background-color: #fff;
    height: 69px;
    width: 69px;
    border-radius: 50%;
    position: absolute;
    top: -34.5px;
    right: 50%;
    overflow: hidden;
    transform: translateX(34.5px);
  }
  .title {
    padding-top: 34px;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
  }
  .list {
    margin: 12px 20px 0 21px;
    background: linear-gradient(to left, #e82063, #f96485);
    color: #fff;
    position: relative;
    border-radius: 8px;
    .list-discount {
      padding-top: 10px;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
      .list-discount-text {
        padding-right: 13px;
        font-size: 14px;
        align-self: flex-end;
      }
    }
    .list-pic {
      width: 50px;
      height: 50px;
      position: absolute;
      bottom: 0;
      right: 4px;
    }
    .date {
      padding: 10px 0 12px 10px;
    }
  }
}
.love {
  display: flex;
  justify-content: center;
  align-items: center;
  .straight {
    width: 25px;
    border-bottom: 1px solid #000;
  }
  .suspect {
    text-align: center;
    font-weight: 550;
    font-size: 21px;
    margin: 0 12px;
  }
}
.lovelist {
  margin: 0px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // background: red;
  .list {
    background-color: #fff;
    margin-top: 15px;
    width: 162px;
    .img {
      width: 100%;
      // height: 138px;
      // background-color: aqua;
    }
    .text {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin: 0px 6px 0px 6px;
      font-weight: 500;
    }
    .pic {
      font-size: 25px;
      color: #f42c20;
      font-weight: bold;
      margin-left: 6px;
    }
  }
}
</style>
